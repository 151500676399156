import { useQuery } from "@tanstack/react-query";
import { getUserApi } from "../../services/apiAuth";

/**
 * @returns the currently logged in user's details
 */
export const useGetCurrentUser = () => {
  const {
    isPending: isLoading,
    data: user,
    error,
  } = useQuery({
    queryFn: () => getUserApi(),
    queryKey: ["user"],
  });

  return { isLoading, user, error };
};
