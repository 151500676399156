import ProjectOverview from "../features/projects/ProjectOverview";

/**
 * @description this is the project overview page component used for routing
 * @returns Project overview page  
 */
const ProjectOverviewPage = () => {
  return <ProjectOverview />;
};

export default ProjectOverviewPage;
