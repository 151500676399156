import React, { ComponentPropsWithRef } from "react";
import styled from "styled-components";
import LeftArrow2 from "../assets/ArrowLeft2.svg?react";
import RightArrow2 from "../assets/ArrowRight2.svg?react";
import LeftArrow from "../assets/LeftArrow.svg?react";
import RightArrow from "../assets/RightArrow.svg?react";

import Icons from "../ui/Icons";

const StyledButton = styled.button`
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

type PropType = ComponentPropsWithRef<"button"> & {
  nested?: boolean;
  $bgColor?: string;
};

export const PrevButton: React.FC<PropType> = (props) => {
  const { children, disabled, nested, $bgColor, ...restProps } = props;
  return (
    <StyledButton
      {...restProps}
      disabled={disabled}
    >
      <Icons disabled={disabled} $bgColor={$bgColor}>
        {nested ? <LeftArrow2 /> : <LeftArrow />}
      </Icons>
      {children}
    </StyledButton>
  );
};

export const NextButton: React.FC<PropType> = (props) => {
  const { children, disabled, nested, $bgColor, ...restProps } = props;

  return (
    <StyledButton
      {...restProps}
      disabled={disabled}
    >
      <Icons disabled={disabled} $bgColor={$bgColor}>
        {nested ? <RightArrow2 /> : <RightArrow />}
      </Icons>
      {children}
    </StyledButton>
  );
};
