import { useEffect, useRef, useState } from "react";
import useOutsideClick from "../hooks/useOutsideClick";
import styled from "styled-components";
import DownArrow from "../assets/ChevronDown.svg?react";

interface DropdownItem {
  id: string;
  name: string;
  imageUrl?: string;
}

interface DropdownProps {
  id: string;
  title?: string;
  data: DropdownItem[];
  selectedId: string;
  onSelect: (id: string) => void;
}

const StyledDrop = styled.div`
  position: relative;
  margin-left: auto;
  font-size: 1.4rem;
  font-weight: 500;
`;

const StyledDropdownButton = styled.button`
  width: 13.5rem;
  height: 4.8rem;
  border: unset;
  border-radius: var(--border-radius-2l);
  padding: 0 1.5rem;
  color: var(--color-recent);
  background-color: white;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  justify-content: space-between;
`;

const StyledDropdownMenu = styled.div`
  position: absolute;
  width: 13.5rem;
  max-height: 15rem;
  overflow-y: auto;
  z-index: 10;
  top: 95%;
  border-radius: var(--border-radius-2l);
  margin-top: 0.5rem;
  border: 2px solid var(--color-grey-400);
`;

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  width: 100%;
  background-color: white;
  &:hover {
    background-color: var(--color-grey-400);
  }
  text-transform: capitalize;
  border-bottom: 3px dashed #ebeff6;

  &:last-child {
    border-bottom: unset;
  }
`;

/**
 * 
 * @returns a dropdown menu with passed in data
 */
const Dropdown = ({
  id,
  title = "Recent",
  data,
  selectedId,
  onSelect,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<DropdownItem | undefined>(
    selectedId ? data?.find((item) => item.id === selectedId) : undefined
  );

  const handleChange = (item: DropdownItem) => {
    setSelectedItem(item);
    onSelect(item.id);
    setIsOpen(false);
  };

  useEffect(() => {
    if (selectedId && data) {
      const newSelectedItem = data.find((item) => item.id === selectedId);
      setSelectedItem(newSelectedItem);
    } else {
      setSelectedItem(undefined);
    }
  }, [selectedId, data]);

  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
  });

  return (
    <StyledDrop ref={dropdownRef}>
      <StyledDropdownButton
        id={id}
        aria-label="Toggle dropdown"
        aria-expanded={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{selectedItem?.name || title}</span>
        <DownArrow />
      </StyledDropdownButton>
      {/* Open */}
      {isOpen && (
        <StyledDropdownMenu>
          <ul role="menu">
            {data?.map((item) => (
              <StyledListItem key={item.id} onClick={() => handleChange(item)}>
                <span>{item.name}</span>
              </StyledListItem>
            ))}
          </ul>
        </StyledDropdownMenu>
      )}
    </StyledDrop>
  );
};

export default Dropdown;
