import { Project, RelatedProjects } from "../types/projectTypes";
import { client } from "./AxiosClient";

export const getAllProjectsAPI = async ({
  skip,
  limit,
}: {
  skip: number;
  limit: number;
}) => {
  const response = await client.get(import.meta.env.VITE_GET_ALL_PROJECTS_EP, {
    params: {
      skip,
      limit,
    },
  });
  return response.data as Project[];
};

export const getProjectByProjectIdAPI = async (projectId: number) => {
  try {
    const response = await client.get(
      import.meta.env.VITE_GET_ALL_PROJECTS_EP + `${projectId}`
    );
    return response.data as Project;
  } catch (error) {
    console.error(error);
  }
};

export const getRelatedProjectsAPI = async (projectId: number) => {
  try {
    const response = await client.get(
      import.meta.env.VITE_GET_RELATED_PROJECTS + `${projectId}`
    );
    return response.data as RelatedProjects[];
  } catch (error) {
    console.error(error);
  }
};
