import { Outlet } from "react-router-dom";
import NavigationComponent from "./NavigationComponent";
import StyledMainDivComponent from "./PageMainDiv";
import ProjectOverlay from "./ProjectOverlay";

/**
 * @returns the entire app layout with child routes
 */
const AppLayout = () => {
  return (
    <StyledMainDivComponent>
      <NavigationComponent />
      <ProjectOverlay>
        <Outlet />
      </ProjectOverlay>
    </StyledMainDivComponent>
  );
};

export default AppLayout;
