import styled from "styled-components";

const StyledSpinner = styled.svg`
  animation: rotate 1.5s linear infinite;
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const MainDiv = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

/**
 * @returns Loader centered in current div
 */
const Spinner = () => (
  <MainDiv>
    <StyledSpinner
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" fill="url(#pattern0_415_572)" />
      <g clipPath="url(#clip0_415_572)">
        <path
          opacity="0.05"
          d="M38.5 24C38.5 21.2485 37.7171 18.5537 36.2428 16.2305C34.7684 13.9073 32.6635 12.0516 30.1738 10.88"
          stroke="#141414"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          opacity="0.1"
          d="M38.5 24C38.5 20.312 37.0947 16.7625 34.57 14.0741"
          stroke="#141414"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          opacity="0.3"
          d="M38.5 24C38.5 22.1725 38.1545 20.3614 37.4818 18.6622"
          stroke="#141414"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M37 24C37 23.9728 36.9999 23.9456 36.9997 23.9183"
          stroke="#141414"
          strokeWidth="6"
          strokeLinecap="round"
        />
      </g>
      <g clipPath="url(#clip1_415_572)">
        <path
          opacity="0.05"
          d="M16.75 11.4427C14.3671 12.8184 12.4249 14.8438 11.1501 17.2822C9.87531 19.7207 9.32065 22.4715 9.55089 25.2134"
          stroke="#141414"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          opacity="0.1"
          d="M16.75 11.4427C13.5561 13.2867 11.1849 16.2784 10.1189 19.8091"
          stroke="#141414"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          opacity="0.3"
          d="M16.75 11.4427C15.1673 12.3564 13.7717 13.5612 12.6365 14.9934"
          stroke="#141414"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M17.5 12.7417C17.4765 12.7553 17.4529 12.769 17.4294 12.7828"
          stroke="#141414"
          strokeWidth="6"
          strokeLinecap="round"
        />
      </g>
      <g clipPath="url(#clip2_415_572)">
        <path
          opacity="0.05"
          d="M16.75 36.5575C19.1329 37.9332 21.858 38.6026 24.6072 38.4874C27.3563 38.3721 30.0159 37.4771 32.2753 35.9068"
          stroke="#141414"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          opacity="0.1"
          d="M16.75 36.5575C19.9439 38.4015 23.7205 38.9591 27.311 38.117"
          stroke="#141414"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          opacity="0.3"
          d="M16.75 36.5575C18.3326 37.4712 20.0738 38.0776 21.8818 38.3445"
          stroke="#141414"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M17.5 35.2584C17.5235 35.272 17.5472 35.2856 17.5708 35.299"
          stroke="#141414"
          strokeWidth="6"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <pattern
          id="pattern0_415_572"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_415_572" />
        </pattern>
        <clipPath id="clip0_415_572">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
        <clipPath id="clip1_415_572">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(18.1436 45.8564) rotate(-120)"
          />
        </clipPath>
        <clipPath id="clip2_415_572">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(45.8564 18.1437) rotate(120)"
          />
        </clipPath>
        <image
          id="image0_415_572"
          width="1"
          height="1"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAQSURBVHgBAQUA+v8AAAAAAAAFAAFkeJU4AAAAAElFTkSuQmCC"
        />
      </defs>
    </StyledSpinner>
  </MainDiv>
);

export default Spinner;
