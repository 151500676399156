import styled from "styled-components";
import CWLogoText from "../assets/CWLogoText.svg?react";
import CWLogo from "../assets/CWLogo.svg?react";
import AtenBare from "../assets/aten-bare.svg?react";
import Aten from "../assets/aten-logo.svg?react";

import { useOverview } from "../context/OverviewActiveContext";
import NavLinks from "../features/navigation/NavLinks";

const StyledContainer = styled.div`
  padding: 5rem 0;
`;

const LogoDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 5rem;
`;

/**
 * @description use this for showing all side nav items
 * @returns Navigation menu
 */
const NavigationComponent = () => {
  const { isOverviewActive, setIsOverviewActive } = useOverview();

  return (
    <StyledContainer onClick={() => setIsOverviewActive(false)}>
      <LogoDiv>{isOverviewActive ? <CWLogo /> : <CWLogoText />}</LogoDiv>
      <NavLinks />
      <LogoDiv>
        {isOverviewActive ? <AtenBare /> : <Aten />}
        <span style={{ fontSize: "1.2rem", color: "var(--color-version)" }}>
          {isOverviewActive ? 'v1.0' : 'VERSION 1.0'}
        </span>
      </LogoDiv>
    </StyledContainer>
  );
};

export default NavigationComponent;
