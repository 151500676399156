import styled from "styled-components";

/**
 * @description used for displaying pagination page numbers
 * @param $isSelected - optional
 * @param disabled - optional
 */
const NumberSpan = styled.span<NumberSpanTypes>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.6rem;
  width: 3.6rem;
  background-color: ${(props) =>
    props.$isSelected ? "var(--color-grey-900)" : "white"};
  color: ${(props) => (props.$isSelected ? "white" : "var(--color-grey-300)")};
  border-radius: var(--border-radius-max);
  font-size: 1.2rem;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? "none" : null)};
  opacity: ${(props) => (props.disabled ? "0.7" : '1')};
`;

type NumberSpanTypes = {
  $isSelected?: boolean;
  disabled?: boolean;
};

export default NumberSpan;
