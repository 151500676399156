import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../common/Constants";
import { removePinItemAPI } from "../services/apiPinning";

/**
 * @description Removing a pinned item
 */
export const useRemovePinItem = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isLoading,
    mutate: removePinItem,
    error,
  } = useMutation({
    mutationFn: ({ pinId }: { pinId: number }) => removePinItemAPI(pinId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PINNED_ITEMS] });
    },
  });

  return { isLoading, removePinItem, error };
};
