import { FC, PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/**
 * @description Used to reroute unauthenticated users to the login page
 * @returns children if user is authenticated
 */
const ProtectedRoute: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const accessDetailsString = localStorage.getItem("accessDetails");
    if (!accessDetailsString) navigate("/login");
  });

  return <div>{children}</div>;
};

export default ProtectedRoute;
