import { useQuery } from "@tanstack/react-query";
import { getProjectByProjectIdAPI } from "../../services/apiProjects";
import { QUERY_KEYS } from "../../common/Constants";

export const useGetProjectById = (id: string) => {
  const {
    isPending: isLoading,
    data: project,
    error,
  } = useQuery({
    queryFn: () => getProjectByProjectIdAPI(+id),
    queryKey: [QUERY_KEYS.PROJECT, id],
  });

  return { isLoading, project, error };
};
