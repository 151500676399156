import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { getAllProjectsAPI } from "../../services/apiProjects";
import { QUERY_KEYS } from "../../common/Constants";
import { useCallback } from "react";
import { Project } from "../../types/projectTypes";

export const useProjects = (
  pinnedProjectIds?: ({ value: string; id: number } | undefined)[],
  limit = -1
) => {
  // todo: modify to fetch all, or accept limit
  const {
    isPending: isLoading,
    data: projects,
    error,
  } = useQuery({
    queryFn: () => getAllProjectsAPI({ skip: 0, limit }),
    queryKey: [QUERY_KEYS.PROJECTS, limit],
    placeholderData: keepPreviousData,
    select: useCallback(
      (data: Project[]) =>
        data?.map((project) => {
          const pin = pinnedProjectIds?.find(
            (item) => item?.value && +item.value === project.id
          )
          return {
            ...project,
            isPinned: !!pin?.value,
            pinId: (pin && pin.id)
          };
        }),
      [pinnedProjectIds]
    ),
  });

  return { isLoading, projects, error };
};
