export const getFormattedCurrency = (value: number) =>
  Intl.NumberFormat("en-IN", { style: "currency", currency: "USD" }).format(
    value
  );

export function* chunks<T>(
  arr: T[] | undefined,
  n: number
): Generator<T[], void> {
  if (!arr || arr.length === 0) return;
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}
