import ProjectSummary from "../features/projects/ProjectSummary";

/**
 * @description this is the project summary page component used for routing
 * @returns Project summary page overview 
 */
const ProjectSummaryPage = () => {
  return <ProjectSummary />;
};

export default ProjectSummaryPage;
