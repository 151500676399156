import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { QUERY_KEYS } from "../../common/Constants";
import { getAllMilestonesAPI } from "../../services/apiMilestones";
import { Milestone } from "../../types/milestoneTypes";

/**
 * @param limit
 * @returns all milestones
 */
export const useGetAllMilestones = (
  pinnedMilestoneIds?: ({ value: string; id: number } | undefined)[],
  limit = -1
) => {
  const {
    isPending: isMilestonesLoading,
    data: milestones,
    error,
  } = useQuery({
    queryFn: () => getAllMilestonesAPI({ skip: 0, limit }),
    queryKey: [QUERY_KEYS.MILESTONES, limit],
    select: useCallback(
      (data: Milestone[]) =>
        data?.map((milestone) => {
          const pin = pinnedMilestoneIds?.find(
            (item) => item?.value && +item.value === milestone.id
          );
          return {
            ...milestone,
            isPinned: !!pin?.value,
            pinId: pin && pin.id,
          };
        }),
      [pinnedMilestoneIds]
    ),
  });

  return { isMilestonesLoading, milestones, error };
};
