import AllProjects from "../features/projects/AllProjects";

/**
 * @description this is the projects page component used for routing
 * @returns Projects page overview 
 */
const Projects = () => {
  return <AllProjects />;
};

export default Projects;
