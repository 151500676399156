import { AxiosError } from "axios";
import { SavedItem, SaveItemRequestBody } from "../types/savedItemTypes";
import { client } from "./AxiosClient";

export const getPinnedItemsAPI = async (userId: string) => {
  try {
    const response = await client.get(
      import.meta.env.VITE_PIN_ITEM + `${userId}/user`
    );

    return response.data as SavedItem[];
  } catch (error) {
    console.error(error);
  }
};

export const savePinItemAPI = async ({
  type,
  value,
  user_id,
  app_module = "capital works",
}: SaveItemRequestBody) => {
  try {
    const response = await client.post(import.meta.env.VITE_PIN_ITEM, {
      type,
      value,
      user_id,
      app_module,
    });

    return response.data as SavedItem;
  } catch (error) {
    const newError = error as AxiosError;
    throw new Error(newError.message);
  }
};

export const removePinItemAPI = async (pinId: number) => {
  try {
    const response = await client.delete(
      import.meta.env.VITE_PIN_ITEM + `${pinId}`
    );

    return response.data;
  } catch (error) {
    const newError = error as AxiosError;
    throw new Error(newError.message);
  }
};
