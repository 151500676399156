import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import More from "../assets/More.svg?react";
import useOutsideClick from "../hooks/useOutsideClick";
import { PinHandler } from "../types/savedItemTypes";

type ListItem = {
  title: string;
  imageComponent: () => React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  to?: string;
  pinningHandler?: PinHandler;
  pinningDetails?: {
    type: string;
    value: number;
    savePin: boolean;
    pinId?: number;
  };
};

const StyledDrop = styled.div`
  position: relative;
  cursor: pointer;
`;

const MenuLayout = styled.div<{ $positionOnTop: boolean }>`
  position: absolute;
  width: 23rem;
  max-height: 32rem;
  overflow-y: auto;
  z-index: 10;
  right: 100%;
  border-radius: var(--border-radius-2l);
  margin-top: 0.5rem;
  border: 2px solid var(--color-grey-400);
  ${(props) => (props.$positionOnTop ? "top: 0%" : "bottom: 0%")}
`;

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  cursor: pointer;
  padding: 1.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  background-color: white;
  &:hover {
    background-color: var(--color-grey-400);
  }
  text-transform: capitalize;
  border-bottom: 3px dashed #ebeff6;

  &:last-child {
    border-bottom: unset;
  }
`;

const MoreDiv = styled.div`
  display: flex;
  align-items: center;
`

type ContextProps = {
  id: string;
  listItems: ListItem[];
  top: () => { topVal: number; headerPos: number };
};

/**
 * @param id
 * @param listItems {ListItem[]}
 * @returns Context menu positioned on the left with the passed list items
 */
const ContextMenu = ({ id, listItems, top }: ContextProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleClick = (item: ListItem) => {
    setIsOpen(false);
    if (item.to) navigate(`${item.to}`);
    else if (item.pinningDetails) {
      const { type, value, savePin, pinId } = item.pinningDetails;
      item.pinningHandler?.(type, `${value}`, savePin, pinId);
    }
    return;
  };

  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
  });

  const { topVal } = top();

  const diffVal = id === "tasks-table-menu" ? 58 : 90;

  return (
    <StyledDrop ref={dropdownRef}>
      <MoreDiv
        id={id}
        aria-label="Toggle context"
        aria-expanded={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        <More />
      </MoreDiv>
      {isOpen && (
        <MenuLayout $positionOnTop={topVal < diffVal * 4}>
          <ul role="menu">
            {listItems.map((item, index) => {
              const Comp = item.imageComponent();
              return (
                <StyledListItem key={index} onClick={() => handleClick(item)}>
                  <span>{item.title}</span>
                  <Comp />
                </StyledListItem>
              );
            })}
          </ul>
        </MenuLayout>
      )}
    </StyledDrop>
  );
};

export default ContextMenu;
