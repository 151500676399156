import { AccessTokenResponse, User } from "../types/authTypes";
import { client } from "./AxiosClient";

export const accessTokenAPI = async ({
  username = "",
  password = "",
}) => {
  const data = new FormData();
  data.append("username", username);
  data.append("password", password);

  const response = await client.post(
    import.meta.env.VITE_ACCESS_TOKEN_EP,
    data
  );

  return response.data as AccessTokenResponse;
};

export const refreshTokenAPI = async (refresh_token: string) => {
  const response = await client.post(import.meta.env.VITE_REFRESH_TOKEN_EP, {
    refresh_token,
  });

  return response.data as AccessTokenResponse;
};

export const getUserApi = async () => {
  const response = await client.get(import.meta.env.VITE_GET_USER);
  return response.data as User;
};
