import { useQuery } from "@tanstack/react-query";
import { getMilestonesByProjectAPI } from "../../services/apiMilestones";
import { QUERY_KEYS } from "../../common/Constants";
import { Milestone } from "../../types/milestoneTypes";
import { useCallback } from "react";

export const useGetMilestoneByProjectId = (
  projectId: string,
  pinnedMilestoneIds?: ({ value: string; id: number } | undefined)[],
  pinnedTaskIds?: ({ value: string; id: number } | undefined)[]
) => {
  const {
    isPending: isLoading,
    data: milestones,
    error,
  } = useQuery({
    queryFn: () => getMilestonesByProjectAPI(+projectId),
    queryKey: [QUERY_KEYS.MILESTONES, QUERY_KEYS.PROJECT, projectId],
    select: useCallback(
      (data: Milestone[]) =>
        data?.map((milestone) => {
          const pin = pinnedMilestoneIds?.find(
            (item) => item?.value && +item.value === milestone.id
          );
          const newTasks = milestone.tasks.map((task) => {
            const taskPin = pinnedTaskIds?.find(
              (item) => item?.value && +item.value === task.id
            );
            return {
              ...task,
              isPinned: !!taskPin?.value,
              pinId: taskPin && taskPin.id,
            };
          });
          return {
            ...milestone,
            isPinned: !!pin?.value,
            pinId: pin && pin.id,
            tasks: newTasks,
          };
        }),
      [pinnedMilestoneIds, pinnedTaskIds]
    ),
  });

  return { isLoading, milestones, error };
};
