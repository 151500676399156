import HomeOverview from "../features/Home/HomeOverview";

/**
 * @description this is the home page component used for routing
 * @returns Home page overview 
 */
const Home = () => {
  return <HomeOverview />;
};

export default Home;
