import { useMutation } from "@tanstack/react-query";
import { accessTokenAPI } from "../../services/apiAuth";
import { LoginCred } from "../../types/authTypes";

/**
 * @returns Access token details for the logged in user
 */
export const useAccessToken = () => {
  const {
    isPending: isLoading,
    mutate: getAccessToken,
    error,
  } = useMutation({
    mutationFn: ({ username, password }: LoginCred) =>
      accessTokenAPI({ username, password }),
    onSuccess: (data) => {
      localStorage.setItem("accessDetails", JSON.stringify(data));
    },
    onError: (error) => {
      localStorage.clear();
      console.log(error);
    },
  });

  return { isLoading, getAccessToken, error };
};
