import AllMilestones from "../features/milestones/AllMilestones";

/**
 * @description this is the Milestones page component used for routing
 * @returns Milestones page overview
 */
const Milestones = () => {
  return <AllMilestones />;
};

export default Milestones;
