import styled from "styled-components";
import GlobalStyles from "../styles/GlobalStyles";
import ButtonCTA from "./ButtonCTA";

const StyledError = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  color: white;
  gap: 2rem;
`;

const ErrorFallback = ({
  error,
  resetError,
}: {
  error: unknown;
  resetError: () => void;
}) => {
  const newError = error as Error;
  return (
    <>
      <GlobalStyles />
      <StyledError>
        <h1> Something went wrong 🫣</h1>
        <p>{newError.message}</p>
        <ButtonCTA onClick={resetError} $buttonType="secondary">
          Try Again
        </ButtonCTA>
      </StyledError>
    </>
  );
};

export default ErrorFallback;
