import styled from "styled-components";
import { useOverview } from "../context/OverviewActiveContext";
import { FC, PropsWithChildren } from "react";

const StyledMainDiv = styled.div<{ $gridSize?: string }>`
  height: 100dvh;
  display: grid;
  grid-template-columns: ${(props) => props.$gridSize};
`;

/**
 * @returns The main layout of every page (side nav + main content)
 */
const StyledMainDivComponent: FC<PropsWithChildren> = ({ children }) => {
  const { isOverviewActive } = useOverview();

  return (
    <StyledMainDiv $gridSize={isOverviewActive ? "9.2rem 15fr" : "1fr 7fr"}>
      {children}
    </StyledMainDiv>
  );
};

export default StyledMainDivComponent;
