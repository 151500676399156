import { Link } from "react-router-dom";
import styled from "styled-components";

const NoDataCard = ({
  title,
  createLink,
  height,
}: {
  title: string;
  createLink?: string;
  height?: string;
}) => {
  // todo: verify all create links being sent

  const StyledNoData = styled.div<{ height?: string }>`
    height: ${(props) => props.height || "100%"};
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-style: italic;
    color: var(--color-no-data-text);
    background-color: var(--color-no-data-bg);
    border-radius: var(--border-radius-xl);

    & a {
      font-weight: 700;
      text-decoration: underline;
    }
    gap: 1rem;
  `;

  return (
    <StyledNoData height={height}>
      <p>No {title} to show. </p>
      {createLink && <Link to={createLink}>{` Create >`}</Link>}
    </StyledNoData>
  );
};

export default NoDataCard;
