import { DOTS, usePagination } from "../hooks/usePagination";
import { FC } from "react";
import styled from "styled-components";
import NumberSpan from "./NumberSpan";
import { Table } from "@tanstack/react-table";
import { Project } from "../types/projectTypes";
import { Milestone, Task } from "../types/milestoneTypes";

type PageProps = {
  siblingCount?: number;
  table: Table<Project> | Table<Task> | Table<Milestone>;
};

const ItemCount = styled.div`
  display: flex;
  gap: 0.5rem;
  margin: 0 3.5rem;
  align-items: center;
`;

/**
 * 
 * @param props 
 * @returns an array of page numbers according to current page size and overall item count
 */
const PageNumbers: FC<PageProps> = (props) => {
  const { siblingCount = 1, table } = props;
  const currentPage = table.getState().pagination.pageIndex + 1;

  const paginationRange = usePagination({
    currentPage: table.getState().pagination.pageIndex + 1,
    totalPageCount: table.getPageCount(),
    siblingCount,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  return (
    <ItemCount>
      <NumberSpan
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        {"<"}
      </NumberSpan>
      {paginationRange.map((pageNumber, index) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return <NumberSpan key={index}>&#8230;</NumberSpan>;
        }
        return (
          <NumberSpan
            onClick={() => table.setPageIndex(pageNumber - 1)}
            $isSelected={currentPage === pageNumber}
            key={index}
          >
            {pageNumber}
          </NumberSpan>
        );
      })}
      <NumberSpan
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        {">"}
      </NumberSpan>
    </ItemCount>
  );
};

export default PageNumbers;
