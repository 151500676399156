import styled from "styled-components";
import ArrowUpRight from "../../assets/ArrowUpRight.svg?react";
import Check from "../../assets/CheckCircle.svg?react";
import Dollar from "../../assets/CurrencyCircleDollar.svg?react";
import Hourglass from "../../assets/Hourglass.svg?react";
import Location from "../../assets/LocationBlack.svg?react";
import Notification from "../../assets/Notification.svg?react";
import Search from "../../assets/Search.svg?react";
import BackButton from "../../assets/BackButton.svg?react";

import moment from "moment";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ButtonCTA from "../../ui/ButtonCTA";
import Icons from "../../ui/Icons";
import NoDataCard from "../../ui/NoDataCard";
import ProgressBar from "../../ui/ProgressBar";
import Spinner from "../../ui/Spinner";
import ToggleButton from "../../ui/ToggleButton";
import ProfileLink from "../navigation/ProfileLink";
import { useGetMilestoneByProjectId } from "./useGetMilestoneByProjectId";
import { useGetProjectById } from "./useGetProjectById";
import { getFormattedCurrency } from "../../utils/common";

const SummaryContainer = styled.div`
  padding: 4.2rem 3.5rem;
`;

const SideContainer = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
`;

const ProjectHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 4.2rem 0;
  align-items: center;
`;

const Heading = styled.div`
  display: flex;
  gap: 2rem;
  & span {
    font-size: 1.4rem;
    font-weight: 400;
  }
  & h2 {
    font-size: 1.4rem;
    font-weight: 400;
    text-transform: uppercase;
  }
`;

const ProjectEstimateContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(4, 1fr);
  align-items: center;
  gap: 2rem;
`;

const ProjectCostEstimate = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  font-weight: 400;
  & span {
    font-size: 4rem;
    color: var(--color-grey-300);
  }
  & p {
    font-size: 8rem;
  }
  letter-spacing: -4px;
`;

const ProjectGridItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  & h1 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: capitalize;
  }
  & h2 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
  }
`;

const LocationDiv = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
`;

const BudgetContainer = styled.div<{
  $frame1: string;
  $frame2: string;
  $frame3: string;
}>`
  display: grid;
  grid-template-columns: ${(props) =>
    `${props.$frame1}fr ${props.$frame2}fr ${props.$frame3}fr`};
  width: 100%;
  gap: 1rem;
  margin-top: 2rem;
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1rem;
  & p {
    text-transform: uppercase;
  }
`;

const ProgressDiv = styled.div`
  background-color: #cfd5e3;
  border-radius: var(--border-radius-2xl);
  width: 100%;
`;

const MilestonesAndActivity = styled.div`
  display: grid;
  margin-top: 4rem;
  grid-template-columns: 2.5fr 1fr;
  gap: 2rem;
`;

const MilestoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MilestoneTitle = styled.h1`
  align-self: end;
  font-size: 1.4rem;
  text-transform: uppercase;
`;

const MilestoneToggles = styled.div`
  background-color: white;
  display: flex;
  border-radius: var(--border-radius-2l);
  height: 4.8rem;
  align-items: center;
`;

const Milestones = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  height: 32rem;
  overflow-y: auto;
`;

const EachMilestone = styled.div<{ $isActive?: boolean }>`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 0.5rem;
  background-color: white;
  border-radius: var(--border-radius-xl);
  padding: 2rem;
  font-size: 1.2rem;
  min-height: 15rem;
`;

const EachMilestoneTitle = styled.span`
  font-size: 1.2rem;
  font-weight: 700;
`;

const StatusSpan = styled.span`
  color: var(--color-in-progress);
  font-weight: 700;
  font-size: 1.2rem;
`;

const StatusP = styled.p`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  justify-self: end;
  align-self: self-start;
`;

const StatusCircle = styled.span`
  height: 0.8rem;
  width: 0.8rem;
  // todo: create a map for status colors
  background-color: var(--color-in-progress);
  border-radius: var(--border-radius-2l);
`;

const ViewSpan = styled.span`
  color: var(--color-button-secondary);
  font-weight: 800;
  align-self: end;
  cursor: pointer;
`;

const ProjectPara = styled.p`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  grid-column: 1/-1;
`;

const ActivityContainer = styled.div`
  background-color: white;
  border-radius: var(--border-radius-2l);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 3rem;
`;

const DateDiv = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 700;

  & p {
    color: var(--color-grey-400);
    &:first-child {
      color: var(--color-percent-complete-sec);
    }
  }
  margin-bottom: 1rem;
`;

const PercentDiv = styled.div`
  & p:last-child {
    font-size: 1.2rem;
    font-weight: 400;
  }
`;

/**
 * @returns individual project summary by project ID
 */
const ProjectOverview = () => {
  const { projectId } = useParams();
  const [isMilestoneActive, setIsMilestoneActive] = useState(true);
  const navigate = useNavigate();

  // API data
  const { isLoading: isProjectLoading, project } = useGetProjectById(
    projectId || "1"
  );
  const {
    isLoading: isMilestoneLoading,
    milestones,
    error: milestoneError,
  } = useGetMilestoneByProjectId(projectId || "1");

  if (isProjectLoading || isMilestoneLoading) return <Spinner />;

  const tasks = milestones?.map((milestone) => milestone.tasks)?.flat() || [];

  const projectEstimate = getFormattedCurrency(103000)
    .replace("$", "")
    .split(".");

  return (
    <SummaryContainer>
      <ProjectHeader>
        <Heading>
          <Icons onClick={() => navigate(-1)}>
            <BackButton />
          </Icons>
          <div>
            <h1>
              Projects <span>| Project Overview </span>
            </h1>
            <h2>{project?.title}</h2>
          </div>
        </Heading>
        <SideContainer>
          <Icons>
            {/* todo: search functionality */}
            <Search />
          </Icons>
          <Icons>
            {/* todo: notifications functionality */}
            <Notification />
          </Icons>
          <ProfileLink />
          <ButtonCTA $buttonType="secondary">
            <span>Project Details</span>
            <ArrowUpRight />
          </ButtonCTA>
        </SideContainer>
      </ProjectHeader>
      <ProjectEstimateContainer>
        <ProjectGridItem>
          <h2>Project estimate</h2>
          <ProjectCostEstimate>
            <p>
              <span>$</span>
              {projectEstimate[0]}
              <span>.{projectEstimate[1]}</span>
            </p>
          </ProjectCostEstimate>
        </ProjectGridItem>
        <ProjectGridItem>
          <h2>Cost centre</h2>
          {/* get cost centre from project */}
          <h1>Housing crisis review</h1>
        </ProjectGridItem>
        <ProjectGridItem>
          <h2>Project manager</h2>
          <h1>
            {project?.responsible.first_name +
              " " +
              project?.responsible.last_name}{" "}
          </h1>
        </ProjectGridItem>
        <ProjectGridItem>
          <h2>Project owner</h2>
          <h1>{project?.owner.first_name + " " + project?.owner.last_name}</h1>
        </ProjectGridItem>
        <LocationDiv>
          <Icons>
            <Location />
          </Icons>
          <ProjectGridItem>
            <h2>Location</h2>
            <h1>Location XYZ</h1>
          </ProjectGridItem>
        </LocationDiv>
      </ProjectEstimateContainer>
      {/* todo: send frame sizes using finance API */}
      <BudgetContainer $frame1="18000" $frame2="18000" $frame3="67000">
        <ProgressContainer>
          <p>Spent</p>
          <ProgressBar $bgColor="var(--color-budget-used-sec)">
            <Icons $bgColor="#22252A1A">
              <Check />
            </Icons>
            <p>{getFormattedCurrency(18000)}</p>
          </ProgressBar>
        </ProgressContainer>
        <ProgressContainer>
          <p>Committed</p>
          <ProgressBar $bgColor="var(--color-budget-in-use)">
            <Icons $bgColor="#22252A1A">
              <Hourglass />
            </Icons>
            <p>{getFormattedCurrency(18000)}</p>
          </ProgressBar>
        </ProgressContainer>
        <ProgressContainer>
          <p>Balance</p>
          <ProgressBar $bgColor="var(--color-budget-remaining)">
            <Icons $bgColor="#22252A1A">
              <Dollar />
            </Icons>
            <p>{getFormattedCurrency(67000)}</p>
          </ProgressBar>
        </ProgressContainer>
      </BudgetContainer>
      <MilestonesAndActivity>
        <MilestoneContainer>
          <SectionHeader>
            <MilestoneTitle>Milestones</MilestoneTitle>
            <MilestoneToggles>
              <ToggleButton
                $buttonActive={isMilestoneActive}
                onClick={() => setIsMilestoneActive(true)}
                $buttonSize="small"
              >
                Milestones
              </ToggleButton>
              <ToggleButton
                $buttonActive={!isMilestoneActive}
                onClick={() => setIsMilestoneActive(false)}
                $buttonSize="small"
              >
                Tasks
              </ToggleButton>
            </MilestoneToggles>
          </SectionHeader>
          <Milestones>
            {milestoneError && (
              <NoDataCard
                title={isMilestoneActive ? "milestones" : "tasks"}
                createLink={`/${
                  isMilestoneActive ? "milestones" : "tasks"
                }/create`}
              />
            )}
            {isMilestoneActive
              ? milestones?.map((milestone) => (
                  <EachMilestone key={milestone.id}>
                    <EachMilestoneTitle>{milestone.title}</EachMilestoneTitle>
                    <StatusP>
                      <StatusCircle></StatusCircle>
                      <StatusSpan>{milestone.status?.title}</StatusSpan>
                    </StatusP>
                    <ProjectPara>
                      <span>
                        {moment(milestone.start_year).format("DD/MM/YYYY")} -{" "}
                        {moment(milestone.end_year).format("DD/MM/YYYY")}
                      </span>
                      <span>
                        {milestone.service?.title}|{milestone.project?.title}
                      </span>
                    </ProjectPara>
                    <ViewSpan>{"View >"}</ViewSpan>
                  </EachMilestone>
                ))
              : tasks?.map((task) => (
                  <EachMilestone key={task.id}>
                    <EachMilestoneTitle>{task.title}</EachMilestoneTitle>
                    <StatusP>
                      <StatusCircle></StatusCircle>
                      <StatusSpan>{task.status?.title}</StatusSpan>
                    </StatusP>
                    <ProjectPara>
                      <span>
                        {moment(task.start_date).format("DD/MM/YYYY")} -{" "}
                        {moment(task.due_date).format("DD/MM/YYYY")}
                      </span>
                      <span>{task.service?.title}</span>
                    </ProjectPara>
                    <ViewSpan>{"View >"}</ViewSpan>
                  </EachMilestone>
                ))}
          </Milestones>
        </MilestoneContainer>
        <ActivityContainer>
          <SectionHeader>
            <MilestoneTitle>Activity</MilestoneTitle>
            <StatusP>
              <StatusCircle></StatusCircle>
              {/* todo: get updated by from project */}
              <StatusSpan>Updated 12M</StatusSpan>
            </StatusP>
          </SectionHeader>
          <ProjectGridItem>
            <h2>Percentage Complete | Days Remaining</h2>
            <ProgressDiv>
              {/* todo: remove dummy values for % */}
              <ProgressBar
                $bgColor="#4BC7A2"
                width={project?.percentage_complete}
              >
                <Icons $bgColor="#22252A1A">
                  <Check />
                </Icons>
                {/* todo: add days remaining and bottom div for dates */}
                <PercentDiv>
                  <p>{project?.percentage_complete}%</p>
                  <p>
                    {moment(project?.start_year).diff(
                      moment(project?.end_year),
                      "days"
                    ) + 10}{" "}
                    Days
                  </p>
                </PercentDiv>
              </ProgressBar>
            </ProgressDiv>
            <DateDiv>
              <p>{moment(project?.start_year).format("DD/MM/YYYY")}</p>
              <p>{moment(project?.end_year).format("DD/MM/YYYY")}</p>
            </DateDiv>
          </ProjectGridItem>{" "}
          <ProjectGridItem>
            <h2>Project Status</h2>
            {/* get overall status from project */}
            <h1>In Progress</h1>
          </ProjectGridItem>{" "}
          <ProjectGridItem>
            <h2>Progress Status</h2>
            <h1>{project?.status.title}</h1>
          </ProjectGridItem>{" "}
          <ProjectGridItem>
            <h2>Project Type</h2>
            <h1>{project?.project_type.title}</h1>
          </ProjectGridItem>
        </ActivityContainer>
      </MilestonesAndActivity>
    </SummaryContainer>
  );
};

export default ProjectOverview;
