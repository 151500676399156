import AllTasks from "../features/tasks/AllTasks";

/**
 * @description this is the Tasks page component used for routing
 * @returns Tasks page overview
 */
const Tasks = () => {
  return <AllTasks />;
};

export default Tasks;
