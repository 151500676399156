export const SORT_OPTIONS = {
  PAST: "past",
  RECENT: "recent",
  PRIORITY: "priority",
};

export const DEFAULT_SORT_OPTIONS = [
  { id: "1", name: SORT_OPTIONS.RECENT },
  { id: "2", name: SORT_OPTIONS.PAST },
  { id: "3", name: SORT_OPTIONS.PRIORITY },
];

export const TASK_STATUS = {
  TODO: "Not Started",
  PENDING: "Pending",
  COMPLETED: "Complete",
  INPROGRESS: "In Progress",
  HOLD: "On Hold",
  ASSIGNED: "Assigned",
  CANCELED: "Cancelled",
};

export const QUERY_KEYS = {
  PROJECT: "project",
  PROJECTS: "projects",
  TASKS: "tasks",
  MILESTONES: "milestones",
  RELATED_PROJECTS: "related-projects",
  PINNED_ITEMS: "pinned-items",
};

export const PINNED_ITEM_TYPES = {
  PROJECT: "project",
  MILESTONE: "milestone",
  TASK: "task",
  SUBTASK: "subtask",
};
