import { useQuery } from "@tanstack/react-query";
import { getRelatedProjectsAPI } from "../../services/apiProjects";
import { QUERY_KEYS } from "../../common/Constants";

export const useGetRelatedProjects = (id: string) => {
  const {
    isPending: isRelatedProjectsLoading,
    data: relatedProjects,
    error,
  } = useQuery({
    queryFn: () => getRelatedProjectsAPI(+id),
    queryKey: [QUERY_KEYS.RELATED_PROJECTS, id],
  });

  return { isRelatedProjectsLoading, relatedProjects, error };
};
