import styled from "styled-components";
import ArrowRightCircle from "../../assets/ArrowRightCircle.svg?react";
import ArrowUpRight from "../../assets/ArrowUpRight.svg?react";
import BackButton from "../../assets/BackButton.svg?react";
import Bookmark from "../../assets/Bookmark.svg?react";
import Chat from "../../assets/Chat.svg?react";
import Contract from "../../assets/Contract.svg?react";
import DeleteCon from "../../assets/DeleteCon.svg?react";
import DownArrow from "../../assets/DownArrow.svg?react";
import Edit from "../../assets/Edit.svg?react";
import EditPencil from "../../assets/EditPencil.svg?react";
import Expand from "../../assets/Expand.svg?react";
import Filter from "../../assets/Filter.svg?react";
import Folders from "../../assets/Folders.svg?react";
import Goals from "../../assets/Goals.svg?react";
import Info from "../../assets/Info.svg?react";
import Jump from "../../assets/Jump.svg?react";
import Location from "../../assets/Location.svg?react";
import More from "../../assets/More.svg?react";
import Notification from "../../assets/Notification.svg?react";
import Paper from "../../assets/Paper.svg?react";
import PinFilled from "../../assets/PinFilled.svg?react";
import PushPinSlash from "../../assets/PushPinSlash.svg?react";
import PushPin from "../../assets/PushPin.svg?react";
import Rearrange from "../../assets/Rearrange.svg?react";
import Search from "../../assets/Search.svg?react";
import SortIcon from "../../assets/SortIcon.svg?react";
import Trash from "../../assets/Trash.svg?react";
import Upload from "../../assets/Upload.svg?react";

import {
  closestCorners,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./Swiper.css";

import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { PINNED_ITEM_TYPES, TASK_STATUS } from "../../common/Constants";
import { SCHEDULE_MAP } from "../../common/ScheduleMap";
import { useGetPinnedItems } from "../../hooks/useGetPinnedItems";
import { useRemovePinItem } from "../../hooks/useRemovePinnedItem";
import { useSavePinItem } from "../../hooks/useSavePinItem";
import { Task } from "../../types/milestoneTypes";
import { PinHandler } from "../../types/savedItemTypes";
import ButtonCTA from "../../ui/ButtonCTA";
import ContextMenu from "../../ui/ContextMenu";
import Icons from "../../ui/Icons";
import NoDataCard from "../../ui/NoDataCard";
import NumberSpan from "../../ui/NumberSpan";
import PageNumbers from "../../ui/PageNumbers";
import Spinner from "../../ui/Spinner";
import ToggleButton from "../../ui/ToggleButton";
import { NextButton, PrevButton } from "../../utils/CarouselButtons";
import { getOffset } from "../../utils/GetOffset";
import { useGetCurrentUser } from "../authentication/useGetCurrentUser";
import ProfileLink from "../navigation/ProfileLink";
import { useGetMilestoneByProjectId } from "./useGetMilestoneByProjectId";
import { useGetProjectById } from "./useGetProjectById";
import { useGetRelatedProjects } from "./useGetRelatedProjects";
import toast from "react-hot-toast";
import { Swiper as SwiperType } from "swiper/types";

const SummaryContainer = styled.div`
  padding: 4.2rem 3.5rem;
`;

const SideContainer = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
`;

const ProjectHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 4.2rem 0;
  align-items: center;
`;

const Heading = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  & span {
    font-size: 1.4rem;
    font-weight: 400;
  }
  & h2 {
    font-size: 1.4rem;
    font-weight: 400;
    text-transform: uppercase;
  }
`;

const SummaryLayout = styled.div<{ $isSummaryExpanded: boolean }>`
  background-color: white;
  border-radius: var(--border-radius-xl);
  height: ${(props) => (props.$isSummaryExpanded ? "34rem" : "20rem")};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const ProjectInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 2rem 0;
  align-items: center;
  & h1 {
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const ExpandContractContainer = styled.div<{ $isSummaryExpanded: boolean }>`
  justify-self: end;
  align-self: flex-end;
  cursor: pointer;
  & svg:hover {
    transform: ${(props) =>
      props.$isSummaryExpanded ? "scale(0.9)" : "scale(1.1)"};
  }
`;

const MilestoneHeader = styled(ProjectInfoHeader)<{ disabled?: boolean }>`
  justify-content: unset;
  & span {
    font-style: italic;
  }
  gap: 1rem;
  padding: unset;
  margin-top: 2rem;
  opacity: ${(props) => props.disabled && "0.4"};
`;

const SideIconsContainer = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
`;

// todo: make this reusable
const Pill = styled.p<{ $progressStatus: keyof typeof SCHEDULE_MAP }>`
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  color: white;
  background-color: ${(props) => SCHEDULE_MAP[props.$progressStatus]};
  border-radius: var(--border-radius-xl);
  font-weight: 500;
`;

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledRefNo = styled.p`
  font-size: 1.2rem;
  color: var(--color-ref-no);
`;

const StyledProjectTitle = styled.h1`
  font-size: 1.6rem;
  font-weight: 600;
`;

const StyledLocation = styled.div`
  display: flex;
  gap: 0.5rem;
  color: var(--color-location);
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 700;
`;

const ProjectInfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  padding-top: 2rem;
  border-top: 3px dashed #ebeff6;
`;

const ProjectGridItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  & h1 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: capitalize;
  }
  & h2 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
  }
`;

const GoalsAndRelatedGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin: 2rem 0;
`;

const ExpandableContainer = styled.div<{
  $isExpanded: boolean;
}>`
  display: flex;
  padding: 2rem;
  height: ${(props) => (props.$isExpanded ? "25rem" : "4.8rem")};
  background-color: white;
  border-radius: var(--border-radius-xl);
  flex-direction: column;
  justify-content: ${(props) => (props.$isExpanded ? "" : "center")};
  gap: 2rem;
`;

const ExpandTitle = styled.div<{
  $isExpanded: boolean;
}>`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  & svg:last-child {
    transform: rotate(0);
    transform: ${(props) => (props.$isExpanded ? `rotate(-180deg)` : "")};
  }
`;

const GoalsTitle = styled.div`
  display: flex;
  gap: 0.5rem;
  font-weight: 700;
  font-size: 1.2rem;
  align-items: center;
`;

const AllGoals = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  & span:first-child {
    font-size: 1.2rem;
    font-weight: 700;
  }
  touch-action: none;
  & svg {
    cursor: pointer;
  }
`;

const GoalItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.2rem;
  font-weight: 400;
  overflow-y: auto;
`;

const GoalItem = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 17fr 1fr 1fr;
  height: 6.6rem;
  border: 3px dashed #ebeff6;
  border-radius: var(--border-radius-xl);
  padding: 1rem;
  gap: 1rem;
  width: 100%;
`;

const GoalDescription = styled.div`
  display: flex;
  justify-content: space-between;
  border-right: 3px dashed #ebeff6;
  height: 100%;
  align-items: center;
  padding-right: 1rem;
`;

const GoalActions = styled.div`
  display: flex;
  border-right: 3px dashed #ebeff6;
  height: 100%;
  align-items: center;
`;

const MilestonesSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 90dvw;
`;

const SwiperContainer = styled.div`
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  min-height: 0;
  min-width: 0;
`;

const EachMilestone = styled.div<{ $isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  border: 0.2rem dashed
    ${(props) => (props.$isActive ? "var(--color-grey-900)" : "transparent")};
  border-radius: var(--border-radius-xl);
  padding: 2rem;
  font-size: 1.2rem;
  height: 16rem;
  cursor: pointer;
`;

const MilestoneTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
`;

const StatusSpan = styled.span`
  color: var(--color-in-progress);
  font-weight: 700;
  font-size: 1.2rem;
`;

const StatusP = styled.p`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  justify-self: end;
  align-self: self-start;
`;

const StatusCircle = styled.span`
  height: 0.8rem;
  width: 0.8rem;
  // todo: create a map for status colors
  background-color: var(--color-in-progress);
  border-radius: var(--border-radius-2l);
`;

const ViewSpan = styled.span`
  color: var(--color-button-secondary);
  font-weight: 800;
  justify-self: end;
  align-self: end;
  cursor: pointer;
`;

const ProjectPara = styled.p`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const StatusView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RelatedItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  overflow-y: auto;
`;

const RelatedItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  border-top: 3px dashed #ebeff6;
  & svg {
    cursor: pointer;
  }
`;

const TasksSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const TaskToggleSection = styled.div`
  display: flex;
  gap: 6rem;
`;

const TaskToggles = styled.div`
  background-color: white;
  display: flex;
  border-radius: var(--border-radius-2l);
  height: 4.8rem;
  align-items: center;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(4, 1fr) 2fr 0.2fr;
  gap: 1.2rem;
  background-color: var(--color-grey-400);
  height: 4.8rem;
  align-items: center;
  border-radius: var(--border-radius-2l);
  font-weight: 500;
  font-size: 1.2rem;
  justify-items: start;
  padding: 0 2.5rem;
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(4, 1fr) 2fr 0.2fr 0.1fr;
  gap: 1.2rem;
  background-color: white;
  height: 4.8rem;
  align-items: center;
  border-radius: var(--border-radius-2l);
  font-size: 1.2rem;
  justify-items: start;
  padding: 0 2.5rem;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;

const ItemCount = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const KanbanGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  min-height: 40rem;
  gap: 1rem;
`;

const KanbanGridItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--color-grey-400-30);
  border-radius: var(--border-radius-xl);
  padding: 1rem;
  & h1 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    margin: 1rem;
  }
`;

const TaskCard = styled(EachMilestone)<{ $isCompleted?: boolean }>`
  background-color: ${(props) => props.$isCompleted && "unset"};
  border: 2px dashed rgba(126, 134, 155, 0.5);
  cursor: unset;
  & svg {
    cursor: pointer;
  }
  height: 16rem;
`;

const BottomDate = styled.span`
  color: var(--color-ref-no);
  font-weight: 700;
  font-size: 1.2rem;
`;

const NoTasks = styled.div`
  align-self: center;
  justify-self: center;
  font-size: 1.4rem;
  font-style: italic;
  color: var(--color-grey-300);
`;

const SliderButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
`;

const StyledPinButton = styled.button`
  :disabled {
    cursor: not-allowed;
  }
  border: none;
  background-color: transparent;
`;

/**
 * @returns individual project summary by project ID
 */
const ProjectSummary = () => {
  const { projectId } = useParams();
  // state
  const [goalsExpanded, setGoalsExpanded] = useState(false);
  const [relatedExpanded, setRelatedExpanded] = useState(false);
  const [summaryExpanded, setSummaryExpanded] = useState(false);
  const [activeMilestoneId, setActiveMilestoneId] = useState<number | null>(
    null
  );
  const [tasks, setTasks] = useState<Task[]>();
  const [currentTaskView, setCurrentTaskView] = useState<
    "table" | "kanban" | "gantt"
  >("table");
  const [sorting, setSorting] = useState<SortingState>([
    { id: "isPinned", desc: true },
  ]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  });

  // swiper state
  const [swiperControl, setSwiperControl] = useState<SwiperType>();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const navigate = useNavigate();

  // todo: get goals from API
  const [goals, setGoals] = useState([
    {
      id: 1,
      description:
        "Finish construction upholding the highest safety and environmental norms",
    },
    {
      id: 2,
      description: "Goal 2",
    },
  ]);

  // API data
  const { isLoading: isSavingPin, pinItem } = useSavePinItem();
  const { isLoading: isRemovingPin, removePinItem } = useRemovePinItem();
  const disablePin = isSavingPin || isRemovingPin;

  const { user } = useGetCurrentUser();
  const { isPinnedItemsLoading, pinnedItems } = useGetPinnedItems(
    user?.id || ""
  );
  const pinnedMilestoneIds =
    pinnedItems?.map((item) => {
      if (item.type === PINNED_ITEM_TYPES.MILESTONE)
        return { value: item.value, id: item.id };
    }) || [];

  const pinnedTaskIds =
    pinnedItems?.map((item) => {
      if (item.type === PINNED_ITEM_TYPES.TASK)
        return { value: item.value, id: item.id };
    }) || [];

  const { isLoading: isProjectLoading, project } = useGetProjectById(
    projectId || "1"
  );
  const {
    isLoading: isMilestoneLoading,
    milestones,
    error: milestoneError,
  } = useGetMilestoneByProjectId(
    projectId || "1",
    pinnedMilestoneIds,
    pinnedTaskIds
  );

  const { relatedProjects, error: relatedProjectsError } =
    useGetRelatedProjects(projectId || "1");
  // tasks table setup
  const columnHelper = createColumnHelper<Task>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        header: "id",
        id: "id",
      }),
      columnHelper.accessor("isPinned", {
        header: "isPinned",
        id: "isPinned",
      }),
      columnHelper.accessor("pinId", {
        header: "pinId",
        id: "pinId",
      }),
      columnHelper.accessor("title", {
        header: "Title",
        id: "title",
      }),
      columnHelper.accessor((row) => moment(row.start_date).format("YYYY"), {
        header: "Start Date",
        id: "start_date",
      }),
      columnHelper.accessor((row) => moment(row.due_date).format("YYYY"), {
        header: "Due Date",
        id: "due_date",
      }),
      columnHelper.accessor(
        (row) => row.responsible.first_name + " " + row.responsible.last_name,
        {
          header: "Responsible",
          id: "responsible",
        }
      ),
      columnHelper.accessor("percentage_completed", {
        header: "% Complete",
        id: "percentage_complete",
      }),
      columnHelper.accessor("status.title", {
        header: "Status",
        id: "status",
      }),
    ],
    [columnHelper]
  );

  // React table config
  const table = useReactTable({
    data: tasks ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      pagination,
      globalFilter,
      columnVisibility: {
        id: false,
        isPinned: false,
        pinId: false,
      },
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
  });

  const handleExpansion = (id: string) => {
    if (id === "goals") {
      setGoalsExpanded((prev: boolean) => !prev);
    }
    if (id === "related") {
      setRelatedExpanded((prev: boolean) => !prev);
    }
  };

  const handleMilestoneClick = useCallback(
    (milestoneId: number) => {
      if (milestoneId === activeMilestoneId) {
        setActiveMilestoneId(null);
        setTasks([]);
      } else {
        setActiveMilestoneId(milestoneId);
        const updatedTasks =
          milestones
            ?.find((milestone) => milestone.id === milestoneId)
            ?.tasks.flat() || [];
        setTasks(updatedTasks);
      }
    },
    [activeMilestoneId, milestones]
  );

  const handlePinning = (
    type: string,
    value: string,
    savePin: boolean,
    event?: React.MouseEvent<HTMLSpanElement>,
    pinId?: number
  ) => {
    event?.stopPropagation();
    handlePinningFromContext(type, value, savePin, pinId);
  };

  const handlePinningFromContext = (
    type: string,
    value: string,
    savePin: boolean,
    pinId?: number
  ) => {
    if (savePin && user) {
      pinItem(
        { type, value, user_id: user.id },
        {
          onSuccess: () => toast.success(`${type} pinned successfully.`),
        }
      );
    } else if (!savePin && pinId) {
      removePinItem(
        { pinId },
        {
          onSuccess: () => toast.success(`${type} unpinned successfully.`),
        }
      );
    }
    // need to manually update tasks, since it's a stable reference
    if (type === PINNED_ITEM_TYPES.TASK) {
      const newTasks = tasks?.map((task) => {
        if (task.id === +value) {
          task.isPinned = savePin;
          task.pinId = pinId;
        }
        return task;
      });
      setTasks(newTasks);
    }
  };

  // drag and drop setup
  // todo: fetch goals from API
  const getGoalPos = (id: number | string) =>
    goals.findIndex((goal) => goal.id === id);
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over?.id) return;
    setGoals((goals) => {
      const originalPos = getGoalPos(active.id);
      const newPos = getGoalPos(over?.id);
      return arrayMove(goals, originalPos, newPos);
    });
  };

  const sensors = useSensors(useSensor(PointerSensor));

  if (isProjectLoading || isMilestoneLoading || isPinnedItemsLoading)
    return <Spinner />;

  return (
    <SummaryContainer>
      <ProjectHeader>
        <Heading>
          <Icons onClick={() => navigate(-1)}>
            <BackButton />
          </Icons>
          <h1>
            Projects <span>| Project Summary </span>
          </h1>
        </Heading>
        <SideContainer>
          <Icons>
            {/* todo: search functionality */}
            <Search />
          </Icons>
          <Icons>
            {/* todo: notifications functionality */}
            <Notification />
          </Icons>
          <ProfileLink />
          <ButtonCTA $buttonType="secondary">
            <span>Project Details</span>
            <ArrowUpRight />
          </ButtonCTA>
        </SideContainer>
      </ProjectHeader>
      <ProjectInfoHeader>
        <h1>Project Information</h1>
        <SideIconsContainer>
          <Icons>
            {" "}
            <Bookmark />
          </Icons>
          <Icons>
            {" "}
            <Chat />{" "}
          </Icons>
          <Icons>
            <Paper />{" "}
          </Icons>
          <Icons>
            <Info />{" "}
          </Icons>
          <Icons>
            <Filter />{" "}
          </Icons>
          <Icons>
            <Upload />{" "}
          </Icons>
          <Icons>
            <More height={20} width={20} />{" "}
          </Icons>
        </SideIconsContainer>
      </ProjectInfoHeader>
      <SummaryLayout $isSummaryExpanded={summaryExpanded}>
        <TitleSection>
          <div>
            <StyledRefNo>{project?.businesscase_id}</StyledRefNo>
            <StyledProjectTitle> {project?.title} </StyledProjectTitle>
          </div>
          <Pill
            $progressStatus={
              project?.status?.title as keyof typeof SCHEDULE_MAP
            }
          >
            <span>{project?.status?.title}</span>
          </Pill>
        </TitleSection>
        <p>{project?.project_type?.title}</p>
        {/* todo: replace with actual location */}
        <StyledLocation>
          <Location />
          <p>{`Robert Robertson, 1234 NW Bobcat Lane, St.Robert, MO 65584-5678.`}</p>
        </StyledLocation>
        {summaryExpanded && (
          <ProjectInfoGrid>
            <ProjectGridItem>
              <h2>Program</h2>
              <h1>Program</h1>
            </ProjectGridItem>
            <ProjectGridItem>
              <h2>Project Owner</h2>
              <h1>{`${project?.owner.first_name} ${project?.owner.last_name}`}</h1>
            </ProjectGridItem>
            <ProjectGridItem>
              <h2>Project Manager</h2>
              <h1>{`${project?.responsible.first_name} ${project?.responsible.last_name}`}</h1>
            </ProjectGridItem>
            <ProjectGridItem>
              <h2>Cost Centre</h2>
              <h1>Sydney 123</h1>
            </ProjectGridItem>
            <ProjectGridItem>
              <h2>Cost</h2>
              <h1>$78,000.00</h1>
            </ProjectGridItem>
            <ProjectGridItem>
              <h2>Start Date</h2>
              <h1>{moment(project?.start_year).format("DD/MM/YYYY")}</h1>
            </ProjectGridItem>
            <ProjectGridItem>
              <h2>End Date</h2>
              <h1>{moment(project?.end_year).format("DD/MM/YYYY")}</h1>
            </ProjectGridItem>
            <ProjectGridItem>
              <h2>Type</h2>
              <h1>Community program</h1>
            </ProjectGridItem>
            <ProjectGridItem>
              <h2>Progress Status</h2>
              <h1>{project?.status.title}</h1>
            </ProjectGridItem>
            <ProjectGridItem>
              <h2>Percentage Complete</h2>
              <h1>{project?.percentage_complete}</h1>
            </ProjectGridItem>
          </ProjectInfoGrid>
        )}
        <ExpandContractContainer
          $isSummaryExpanded={summaryExpanded}
          onClick={() => setSummaryExpanded((prev) => !prev)}
        >
          {summaryExpanded ? <Contract /> : <Expand />}
        </ExpandContractContainer>
      </SummaryLayout>
      <GoalsAndRelatedGrid>
        <ExpandableContainer $isExpanded={goalsExpanded}>
          <ExpandTitle
            $isExpanded={goalsExpanded}
            onClick={() => handleExpansion("goals")}
          >
            <GoalsTitle>
              <Goals />
              <p>Goals</p>
            </GoalsTitle>
            <DownArrow />
          </ExpandTitle>
          {goalsExpanded && (
            <DndContext
              collisionDetection={closestCorners}
              onDragEnd={handleDragEnd}
              sensors={sensors}
            >
              <GoalItems>
                {goals.map((goal, index) => (
                  <SortableContext
                    items={goals}
                    strategy={verticalListSortingStrategy}
                  >
                    <GoalsRenderer goal={goal} index={index} key={goal.id} />
                  </SortableContext>
                ))}
              </GoalItems>
            </DndContext>
          )}
        </ExpandableContainer>
        <ExpandableContainer $isExpanded={relatedExpanded}>
          <ExpandTitle
            $isExpanded={relatedExpanded}
            onClick={() => handleExpansion("related")}
          >
            <GoalsTitle>
              <Folders />
              <p>Related Projects</p>
            </GoalsTitle>
            <DownArrow />
          </ExpandTitle>
          {relatedExpanded && relatedProjectsError && (
            <NoDataCard title="related projects" />
          )}
          {relatedExpanded && relatedProjects?.length && (
            <RelatedItems>
              {relatedProjects?.map((relatedProject) => (
                <RelatedItem>
                  <span
                    key={relatedProject.id}
                    onClick={() =>
                      navigate(`/projects/summary/${relatedProject.id}`)
                    }
                  >
                    {relatedProject.title}
                  </span>
                  <ArrowRightCircle
                    onClick={() =>
                      navigate(`/projects/summary/${relatedProject.id}`)
                    }
                  />
                </RelatedItem>
              ))}
            </RelatedItems>
          )}
        </ExpandableContainer>
      </GoalsAndRelatedGrid>
      <MilestonesSection>
        <MilestoneHeader>
          <h1>Milestones {`[${milestones?.length || 0}]`}</h1>
          <span>{`(Click on a milestone to view it's tasks)`}</span>
        </MilestoneHeader>
        {milestoneError && (
          <NoDataCard title="milestones" createLink="/milestones/create" />
        )}
        <SwiperContainer>
          {milestones && (
            <Swiper
              cssMode
              navigation
              modules={[Navigation]}
              className="milestoneSwiper"
              spaceBetween={20}
              slidesPerView={3}
              onSwiper={(swiper) => setSwiperControl(swiper)}
              onSlideChange={() =>
                setActiveSlideIndex(swiperControl?.activeIndex || 0)
              }
              id="milestoneSwiper"
              loop
            >
              {milestones?.map((milestone) => (
                <SwiperSlide
                  key={milestone.id}
                  className="milestone-swiper-slide"
                >
                  <EachMilestone
                    key={milestone.id}
                    onClick={() => handleMilestoneClick(milestone.id)}
                    $isActive={milestone.id === activeMilestoneId}
                  >
                    <MilestoneTitle>
                      {milestone.title}
                      {milestone.isPinned ? (
                        <StyledPinButton
                          onClick={(event) =>
                            handlePinning(
                              "milestone",
                              `${milestone.id}`,
                              false,
                              event,
                              milestone.pinId
                            )
                          }
                          disabled={disablePin}
                        >
                          <PinFilled />
                        </StyledPinButton>
                      ) : (
                        <StyledPinButton
                          onClick={(event) =>
                            handlePinning(
                              "milestone",
                              `${milestone.id}`,
                              true,
                              event
                            )
                          }
                          disabled={disablePin}
                        >
                          <PushPin />
                        </StyledPinButton>
                      )}
                    </MilestoneTitle>
                    <ProjectPara>
                      <span>
                        {moment(milestone.start_year).format("DD/MM/YYYY")} -{" "}
                        {moment(milestone.end_year).format("DD/MM/YYYY")}
                      </span>
                      <span>
                        {milestone.service?.title +
                          " " +
                          milestone.service?.title +
                          " " +
                          milestone.service?.title +
                          " " +
                          milestone.service?.title +
                          " " +
                          milestone.service?.title}
                      </span>
                    </ProjectPara>
                    {/* <span>{milestone.project?.title}</span> */}
                    <StatusView>
                      {/* TODO: map status to color */}
                      <StatusP>
                        <StatusCircle></StatusCircle>
                        <StatusSpan>{milestone.status?.title}</StatusSpan>
                      </StatusP>
                      <ViewSpan>{"View >"}</ViewSpan>
                    </StatusView>
                  </EachMilestone>
                </SwiperSlide>
              ))}
              <SliderButtonDiv>
                <PrevButton
                  onClick={() => swiperControl?.slideTo(activeSlideIndex - 1)}
                />
                <NextButton onClick={() => swiperControl?.slideNext()} />
              </SliderButtonDiv>
            </Swiper>
          )}
        </SwiperContainer>
        {activeMilestoneId && tasks?.length ? (
          <TasksSection>
            <ProjectInfoHeader>
              <h1>Tasks {`[${tasks?.length || 0}]`}</h1>
              <SideIconsContainer>
                <Icons>
                  <SortIcon />{" "}
                </Icons>
                <Icons>
                  <Filter />{" "}
                </Icons>
                <TaskToggleSection>
                  <TaskToggles>
                    <ToggleButton
                      $buttonActive={currentTaskView === "table"}
                      onClick={() => setCurrentTaskView("table")}
                    >
                      Table View
                    </ToggleButton>
                    <ToggleButton
                      $buttonActive={currentTaskView === "kanban"}
                      onClick={() => setCurrentTaskView("kanban")}
                    >
                      KanBan View
                    </ToggleButton>
                  </TaskToggles>
                </TaskToggleSection>
              </SideIconsContainer>
            </ProjectInfoHeader>
            {
              <TaskViewRender
                table={table}
                currentView={currentTaskView}
                tasks={tasks}
                handlePinning={handlePinningFromContext}
                disablePin={disablePin}
              />
            }
          </TasksSection>
        ) : (
          <MilestoneHeader disabled>
            <h1>Tasks</h1>
            <span>{`[no tasks to show]`}</span>
          </MilestoneHeader>
        )}
      </MilestonesSection>
    </SummaryContainer>
  );
};

export default ProjectSummary;

const getTaskContextMenu = (
  taskId: number,
  isPinned: boolean,
  pinId?: number,
  pinningHandler?: PinHandler
) => {
  return [
    {
      title: isPinned ? "Unpin" : "Pin",
      to: "",
      imageComponent: () => (isPinned ? PushPinSlash : PushPin),
      pinningHandler,
      pinningDetails: {
        type: PINNED_ITEM_TYPES.TASK,
        value: taskId,
        savePin: !isPinned,
        pinId,
      },
    },
    {
      title: "Edit & Complete",
      to: `/tasks/${taskId}`,
      imageComponent: () => Edit,
    },
    {
      title: "Delete",
      to: `/tasks/${taskId}`,
      imageComponent: () => Trash,
    },
    {
      title: "Create Project",
      to: "/projects",
      imageComponent: () => Jump,
    },
  ];
};

const TaskViewRender = ({
  table,
  currentView,
  tasks,
  handlePinning,
  disablePin,
}: {
  table: Table<Task>;
  currentView: string;
  tasks: Task[];
  handlePinning?: PinHandler;
  disablePin?: boolean;
}) => {
  const toDoTasks: Task[] = [],
    inProgressTasks: Task[] = [],
    onHoldTasks: Task[] = [],
    completedTasks: Task[] = [];

  for (const task of tasks) {
    // todo check if there can be more status values
    if (
      task.status.title === TASK_STATUS.TODO ||
      task.status.title === TASK_STATUS.PENDING
    )
      toDoTasks.push(task);
    else if (task.status.title === TASK_STATUS.HOLD) onHoldTasks.push(task);
    else if (task.status.title === TASK_STATUS.INPROGRESS)
      inProgressTasks.push(task);
    else if (task.status.title === TASK_STATUS.COMPLETED)
      completedTasks.push(task);
  }

  if (currentView === "table") {
    return (
      <>
        <TableHeader id="task-table-header">
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <span
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                style={{ cursor: "pointer" }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                {
                  { asc: " 🔼", desc: " 🔽" }[
                    (header.column.getIsSorted() as string) ?? null
                  ]
                }
              </span>
            ))
          )}
        </TableHeader>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id} id={`${row.id}-task`}>
            <span>{row.getValue("title")}</span>
            <span>{moment(row.getValue("start_date")).format("YYYY")}</span>
            <span>{moment(row.getValue("due_date")).format("YYYY")}</span>
            <span>{row.getValue("responsible")}</span>
            <span>{row.getValue("percentage_complete")}</span>
            <Pill
              $progressStatus={
                row.getValue("status") as keyof typeof SCHEDULE_MAP
              }
            >
              <span>{row.getValue("status")}</span>
            </Pill>
            <ContextMenu
              listItems={getTaskContextMenu(
                row.getValue("id"),
                row.getValue("isPinned"),
                row.getValue("pinId"),
                handlePinning
              )}
              id="tasks-table-menu"
              top={() => getOffset("task-table-header", `${row.id}-task`)}
            />
            {!!row.getValue("isPinned") && <PinFilled />}
          </TableRow>
        ))}
        <Pagination>
          <ItemCount>
            <span style={{ fontSize: "1.2rem" }}>Show:</span>
            <NumberSpan
              $isSelected={table.getState().pagination.pageSize === 5}
              onClick={() => table.setPageSize(5)}
            >
              5
            </NumberSpan>
            <NumberSpan
              $isSelected={table.getState().pagination.pageSize === 10}
              onClick={() => table.setPageSize(10)}
            >
              10
            </NumberSpan>
          </ItemCount>
          <PageNumbers table={table} />
        </Pagination>
      </>
    );
  } else if (currentView === "kanban")
    return (
      <KanbanGrid>
        <KanbanGridItem>
          <h1>To-Do</h1>
          {toDoTasks.length ? (
            <TaskCardRenderer
              handlePinning={handlePinning}
              disablePin={disablePin}
              tasks={toDoTasks}
            />
          ) : (
            <NoTasks>No tasks.</NoTasks>
          )}
        </KanbanGridItem>
        <KanbanGridItem>
          <h1>In-progress</h1>
          {inProgressTasks.length ? (
            <TaskCardRenderer
              handlePinning={handlePinning}
              disablePin={disablePin}
              tasks={inProgressTasks}
            />
          ) : (
            <NoTasks>No tasks.</NoTasks>
          )}
        </KanbanGridItem>
        <KanbanGridItem>
          <h1>On-hold</h1>
          {onHoldTasks.length ? (
            <TaskCardRenderer
              handlePinning={handlePinning}
              disablePin={disablePin}
              tasks={onHoldTasks}
            />
          ) : (
            <NoTasks>No tasks.</NoTasks>
          )}
        </KanbanGridItem>
        <KanbanGridItem>
          <h1>Completed</h1>
          {completedTasks.length ? (
            <TaskCardRenderer
              handlePinning={handlePinning}
              disablePin={disablePin}
              tasks={completedTasks}
              completed
            />
          ) : (
            <NoTasks>No tasks.</NoTasks>
          )}
        </KanbanGridItem>
      </KanbanGrid>
    );
  else return null;
};

const TaskCardRenderer = ({
  tasks,
  completed = false,
  handlePinning,
  disablePin,
}: {
  tasks: Task[];
  completed?: boolean;
  handlePinning?: PinHandler;
  disablePin?: boolean;
}) => {
  const ContextMenuDiv = styled.div`
    display: flex;
    gap: 0.5rem;
  `;

  const handlePinningDirect = (
    type: string,
    value: string,
    savePin: boolean,
    event?: React.MouseEvent<HTMLOrSVGElement>,
    pinId?: number
  ) => {
    event?.stopPropagation();
    handlePinning?.(type, value, savePin, pinId);
  };

  return (
    <>
      {tasks?.map((task) => (
        <TaskCard key={task.id} $isCompleted={completed} id="task-card-header">
          <MilestoneTitle>
            {task.title}
            <ContextMenuDiv>
              {task.isPinned ? (
                <StyledPinButton
                  onClick={(e) =>
                    handlePinningDirect(
                      PINNED_ITEM_TYPES.TASK,
                      `${task.id}`,
                      false,
                      e,
                      task.pinId
                    )
                  }
                  disabled={disablePin}
                >
                  <PinFilled />
                </StyledPinButton>
              ) : (
                <StyledPinButton
                  onClick={(e) =>
                    handlePinningDirect(
                      PINNED_ITEM_TYPES.TASK,
                      `${task.id}`,
                      true,
                      e
                    )
                  }
                  disabled={disablePin}
                >
                  <PushPin />
                </StyledPinButton>
              )}
            </ContextMenuDiv>
          </MilestoneTitle>
          <ProjectPara>
            <span>
              {moment(task.start_date).format("DD/MM/YYYY")} -{" "}
              {moment(task.due_date).format("DD/MM/YYYY")}
            </span>
            <span>{task.service.title}</span>
          </ProjectPara>
          {/* <span>{milestone.project?.title}</span> */}
          <StatusView>
            <StatusP>
              <BottomDate>
                {moment(task.start_date).format("DD/MM/YYYY")} -{" "}
                {moment(task.due_date).format("DD/MM/YYYY")}
              </BottomDate>
            </StatusP>
            <ViewSpan>{"View >"}</ViewSpan>
          </StatusView>
        </TaskCard>
      ))}
    </>
  );
};

const GoalsRenderer = ({
  goal,
  index,
}: {
  // change type of goal
  goal: {
    id: number;
    description: string;
  };
  index: number;
}) => {
  const { attributes, listeners, transform, transition, setNodeRef } =
    useSortable({
      id: goal.id,
    });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const svgStyle = {
    cursor: "grab",
  };

  return (
    <AllGoals key={goal.id} style={style} ref={setNodeRef}>
      <span>{index + 1 + "."}</span>
      <GoalItem>
        <GoalDescription>
          <p> {goal.description}</p>
          <EditPencil />
        </GoalDescription>
        <GoalActions>
          <DeleteCon />
        </GoalActions>
        <Rearrange style={svgStyle} {...attributes} {...listeners} />
      </GoalItem>
    </AllGoals>
  );
};
