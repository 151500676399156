import styled from "styled-components";

/**
 * @description reusable progress bar, pass in background color and width
 * @param $bgColor {string}
 * @param $width {number| string} optional
 */
const ProgressBar = styled.div<ProgressBarProps>`
  height: 6.2rem;
  border-radius: var(--border-radius-2xl);
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: ${(props) => props.$bgColor};
  min-width: 15rem;
  padding: 0.8rem;
  font-size: 1.2rem;
  color: white;
  font-weight: 700;
  width: ${(props) => props.width + "%"};
`;

type ProgressBarProps = {
  $bgColor: string;
  width?: number | string;
};

export default ProgressBar;
