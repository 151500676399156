import styled from "styled-components";
import Profile from "../../assets/profile.svg?react";
import Spinner from "../../ui/Spinner";
import { useGetCurrentUser } from "../authentication/useGetCurrentUser";

const ProfileDiv = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: center;
`;

const StyledDesc = styled.p`
  display: flex;
  flex-direction: column;
`;

const StyledSpan = styled.span<{ $isTitle?: boolean }>`
  font-size: 1.2rem;
  font-weight: ${(props) => (props.$isTitle ? "800" : "")};
`;

const ProfileLink = () => {
  const { isLoading, user } = useGetCurrentUser();

  if (isLoading) return <Spinner />;

  return (
    <ProfileDiv>
      <Profile />
      <StyledDesc>
        <StyledSpan $isTitle={true}>{user?.full_name}</StyledSpan>
      </StyledDesc>
    </ProfileDiv>
  );
};

export default ProfileLink;
