import { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import { useOverview } from "../context/OverviewActiveContext";

const StyledOverlay = styled.div`
  background-color: var(--color-grey-100);
  border-radius: var(--border-radius-xl);
  margin: 0.8rem 0.8rem 0.8rem 0;
  /* overflow-x: auto; */
`;

/**
 * 
 * @returns the main overlay for displaying data
 */
const ProjectOverlay: FC<PropsWithChildren> = ({ children }) => {
  const { setIsOverviewActive } = useOverview();

  return (
    <StyledOverlay onClick={() => setIsOverviewActive(true)}>
      {children}
    </StyledOverlay>
  );
};

export default ProjectOverlay;
