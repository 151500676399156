import { useQuery } from "@tanstack/react-query";
import { getPinnedItemsAPI } from "../services/apiPinning";
import { QUERY_KEYS } from "../common/Constants";

export const useGetPinnedItems = (user_id: string) => {
  const {
    isPending: isPinnedItemsLoading,
    data: pinnedItems,
    error,
  } = useQuery({
    queryFn: () => getPinnedItemsAPI(user_id),
    queryKey: [QUERY_KEYS.PINNED_ITEMS, user_id],
    enabled: !!user_id
  });

  return { isPinnedItemsLoading, pinnedItems, error };
};
