import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components";
import ButtonCTA from "../ui/ButtonCTA";
import { useAccessToken } from "../features/authentication/useAccessToken";
import { LoginCred } from "../types/authTypes";
import { useNavigate } from "react-router-dom";
import AtenLogo from "../assets/aten-logo.svg?react";

const LoginLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  flex-direction: column;
  background-image: url("/BGLines.svg");
  gap: 4rem;
`;

const Title = styled.h1`
  color: white;
  font-weight: 400;
`;

const FormContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  height: 46rem;
  width: 44rem;
  border-radius: var(--border-radius-2l);
  padding: 6rem;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  & p {
    font-size: 1.2rem;
    align-self: center;
  }
`;

const StyledInput = styled.input`
  background-color: var(--color-grey-100);
  ::placeholder {
    color: var(-color-placeholder-grey);
  }
  width: 30rem;
  height: 4.8rem;
  border: transparent;
  border-radius: var(--border-radius-2l);
  padding: 0 1rem;
`;

const StyledSpan = styled.span`
  font-size: 1.2rem;
  color: var(--color-button-secondary);
  font-weight: 700;
  margin-top: -1rem;
  margin-bottom: 2rem;
  cursor: pointer;
`;

const ErrorSpan = styled.span`
  font-size: 1.2rem;
  color: red;
  font-weight: 700;
`;

const LogoDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  color: var(--color-version);
`;
const Login = () => {
  const [loginError, setLoginError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginCred>();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("accessDetails");
    if (token) {
      navigate("/home");
    }
  }, [navigate]);

  const { getAccessToken, isLoading } = useAccessToken();

  const onSubmit: SubmitHandler<LoginCred> = (data) => {
    getAccessToken(
      {
        ...data,
      },
      {
        onSettled: (_, error) => {
          if (error) {
            setLoginError(error?.message);
          } else {
            navigate("/home");
          }
        },
      }
    );
  };

  return (
    <LoginLayout>
      <Title>
        capital<strong>WORX</strong>
      </Title>
      <FormContainer>
        <h1>Login</h1>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          {loginError && <ErrorSpan>Incorrect Email or Password</ErrorSpan>}
          <StyledInput
            type="text"
            placeholder="Enter User ID"
            {...register("username", { required: true })}
          />
          {errors.username && <span>This field is required</span>}
          <StyledInput
            type="password"
            placeholder="Enter Password"
            {...register("password", { required: true })}
          />
          {errors.password && <span>This field is required</span>}
          <StyledSpan>Forgot password?</StyledSpan>
          <ButtonCTA
            $buttonType="secondary"
            $buttonSize="large"
            disabled={isLoading}
          >
            Login
          </ButtonCTA>
          <p>
            Don't have an account? <StyledSpan> Sign up!</StyledSpan>
          </p>
        </StyledForm>
      </FormContainer>
      <LogoDiv>
        <p>Version 1.0 | Powered by</p>
        <AtenLogo />
      </LogoDiv>
    </LoginLayout>
  );
};

export default Login;
