import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { QUERY_KEYS } from "../../common/Constants";
import { getAllTasksAPI } from "../../services/apiMilestones";
import { Task } from "../../types/milestoneTypes";

/**
 * @param limit
 * @returns all tasks
 */
export const useGetAllTasks = (
  pinnedTaskIds: ({ value: string; id: number } | undefined)[],
  limit = -1
) => {
  const {
    isPending: isTasksLoading,
    data: tasks,
    error,
  } = useQuery({
    queryFn: () => getAllTasksAPI({ skip: 0, limit }),
    queryKey: [QUERY_KEYS.TASKS, limit],
    select: useCallback(
      (data: Task[]) =>
        data?.map((task) => {
          const pin = pinnedTaskIds.find(
            (item) => item?.value && +item.value === task.id
          );
          return {
            ...task,
            isPinned: !!pin?.value,
            pinId: pin && pin.id,
          };
        }),
      [pinnedTaskIds]
    ),
  });

  return { isTasksLoading, tasks, error };
};
