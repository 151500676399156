import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

type OverviewActiveContextType = {
  isOverviewActive: boolean;
  setIsOverviewActive: React.Dispatch<React.SetStateAction<boolean>>;
};

const defaultOverviewActiveContext = {
  isOverviewActive: false,
  setIsOverviewActive: () => {},
};

export const OverviewActiveContext = createContext<OverviewActiveContextType>(
  defaultOverviewActiveContext
);

export const OverviewActiveContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [isOverviewActive, setIsOverviewActive] = useState(false);
  return (
    <OverviewActiveContext.Provider
      value={{ isOverviewActive, setIsOverviewActive }}
    >
      {children}
    </OverviewActiveContext.Provider>
  );
};

export const useOverview = () => {
  const context = useContext(OverviewActiveContext);
  return context;
};
