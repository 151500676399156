import { useMutation, useQueryClient } from "@tanstack/react-query";
import { savePinItemAPI } from "../services/apiPinning";
import { SaveItemRequestBody } from "../types/savedItemTypes";
import { QUERY_KEYS } from "../common/Constants";

/**
 * @returns Saving a pinned item
 */
export const useSavePinItem = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isLoading,
    mutate: pinItem,
    error,
  } = useMutation({
    mutationFn: ({ type, value, user_id }: SaveItemRequestBody) =>
      savePinItemAPI({ type, value, user_id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PINNED_ITEMS] });
    },
  });

  return { isLoading, pinItem, error };
};
