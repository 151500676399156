import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { OverviewActiveContextProvider } from "./context/OverviewActiveContext";

import Documents from "./pages/Documents";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Programs from "./pages/Programs";
import ProjectOverviewPage from "./pages/ProjectOverview";
import ProjectSummaryPage from "./pages/ProjectSummary";
import Projects from "./pages/Projects";
import Reports from "./pages/Reports";
import GlobalStyles from "./styles/GlobalStyles";
import AppLayout from "./ui/AppLayout";
import ProtectedRoute from "./ui/ProtectedRoute";
import Milestones from "./pages/Milestones";
import Tasks from "./pages/Tasks";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30 * 60 * 1000, // 30mins stale time
      retry: 2,
    },
    mutations: {
      onError: () => toast.error(`Something wasn't right 🫣, please retry.`)
    }
  },
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
function App() {
  return (
    <>
      <OverviewActiveContextProvider>
        <Toaster
          position="top-center"
          gutter={12}
          containerStyle={{ margin: "8px" }}
          toastOptions={{
            success: {
              duration: 3000,
            },
            error: {
              duration: 3000,
            },
            style: {
              border: '2px solid #EB3C7C',
              padding: '16px',
              color: 'var(--color-grey-900)',
              backgroundColor: "#FFE8F0",
            },
            iconTheme: {
              primary: '#EB3C7C',
              secondary: '#FFFAEE',
            },
          }}
        />
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <GlobalStyles />
          <BrowserRouter>
            <SentryRoutes>
              <Route path="login" element={<Login />} />
              <Route
                element={
                  <ProtectedRoute>
                    <AppLayout />
                  </ProtectedRoute>
                }
              >
                <Route index element={<Navigate replace to="home" />}></Route>
                <Route path="home" element={<Home />} />
                <Route path="programs" element={<Programs />} />
                <Route path="projects" element={<Projects />} />
                <Route
                  path="projects/summary/:projectId"
                  element={<ProjectSummaryPage />}
                />
                <Route
                  path="projects/overview/:projectId"
                  element={<ProjectOverviewPage />}
                />
                <Route path="milestones" element={<Milestones />} />
                <Route path="tasks" element={<Tasks />} />
                <Route path="reports" element={<Reports />} />
                <Route path="documents" element={<Documents />} />
              </Route>
            </SentryRoutes>
          </BrowserRouter>
        </QueryClientProvider>
      </OverviewActiveContextProvider>
    </>
  );
}

export default App;
