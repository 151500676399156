import { AxiosError } from "axios";
import { Milestone, Task } from "../types/milestoneTypes";
import { client } from "./AxiosClient";

export const getAllMilestonesAPI = async ({
  skip,
  limit,
}: {
  skip: number;
  limit: number;
}) => {
  const response = await client.get(import.meta.env.VITE_GET_ALL_MILESTONES, {
    params: {
      skip,
      limit,
    },
  });
  return response.data as Milestone[];
};

export const getAllTasksAPI = async ({
  skip,
  limit,
}: {
  skip: number;
  limit: number;
}) => {
  const response = await client.get(import.meta.env.VITE_GET_ALL_TASKS, {
    params: {
      skip,
      limit,
    },
  });
  return response.data as Task[];
};

export const getMilestonesByProjectAPI = async (projectId: number) => {
  try {
    const response = await client.get(
      import.meta.env.VITE_GET_MILESTONES_BY_PROJECT + `${projectId}`
    );
    return response.data as Milestone[];
  } catch (error) {
    const knownError = error as AxiosError;
    throw new Error(knownError.status?.toString());
  }
};

export const getTasksByMilestone = async (milestoneId: number) => {
  try {
    const response = await client.get(
      import.meta.env.VITE_GET_TASKS_BY_MILESTONE + `${milestoneId}`
    );
    return response.data as Task[];
  } catch (error: unknown) {
    // todo handle errors for other APIs
    const knownError = error as AxiosError;
    throw new Error(knownError.message);
  }
};
